import axios from 'axios'
import { useState, useRef } from 'react'
import Matches from './Matches';
import {utils, writeFile} from "xlsx"
import './Home.scss'
import { useEffect } from 'react';

if(process.env.NODE_ENV == 'production'){
    axios.defaults.baseURL = 'https://server-dot-adstra-privacy.uk.r.appspot.com/'
  }else{
    axios.defaults.baseURL = 'http://localhost:8000'
  }

const Home = () => {

    const firstName = useRef('');
    const lastName = useRef('');
    const state = useRef('');
    const zipCode = useRef('');
    const firstNameType = useRef('Exact');
    const lastNameType  = useRef('Exact');
    const stateType  = useRef('Starting');
    const zipCodeType  = useRef('Starting');
    const query = useRef(null)

    const [matchesList, setMatchesList] = useState(null)
    const option = useRef(false)

    var animateButton = function(e) {

      
        //reset animation
        e.target.classList.remove('animate');
        
        e.target.classList.add('animate');

      };
      
     

      useEffect(() => {
        console.log('hi')
        var classname = document.getElementById("query-sumbit");
        classname.addEventListener('click', animateButton, false);

      }, []);
    

    const uploadInfo = async (e) => {
        e.preventDefault()

        await axios.get('checkinfo', 
        { 
            params: 
            { 
                firstName: firstName.current.trim().toLowerCase(), 
                lastName: lastName.current.trim().toLowerCase(), 
                state: state.current.trim().toLowerCase(), 
                zipCode: zipCode.current.trim(),
                firstNameType: firstNameType.current,
                lastNameType: lastNameType.current,
                stateType: stateType.current,
                zipCodeType: zipCodeType.current,
                option: option.current

            } 
        }).then((res) => 
            {
                setMatchesList(res.data)
                query.current = `firstName: '${firstName.current}' lastName: '${lastName.current}' state: '${state.current}' zipCode: '${zipCode.current}'`        
                setTimeout(function(){
                    e.target.classList.remove('animate');
                  },3000);
            }
        )


    }

    function ExportData()
    {

        const filename = 'file.xlsx'
        var ws = utils.json_to_sheet(matchesList);
        var wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Sheet 1");
        writeFile(wb,filename);
    }



    
    return ( 
        <div>
            <div className="screen-box">
                <h1>L2ME Table Lookup Tool</h1>
                <label className="standard-select">First Name</label>
                <div class="input-select" >
                <div className='inputs'>
                        <div class="select">
                            <select id="standard-select" onChange={e => firstNameType.current = e.target.value}>
                                <option value="Exact">Exact</option>
                                <option value="Starting">Starting</option>
                                <option value="Any">Any</option>
                            </select>
                        </div>
                    </div>
                    <div className='inputs'>
                        <input type="text" id="zipCode" name="zipCode" onChange={e =>firstName.current = e.target.value}/>
                    </div>

                </div>
                

                <label className="standard-select">Last Name</label>
                <div class="input-select" >
                <div className='inputs'>
                        <div class="select">
                            <select id="standard-select" onChange={e => lastNameType.current = e.target.value}>
                            <option value="Exact">Exact</option>
                                <option value="Starting">Starting</option>
                                <option value="Any">Any</option>
                            </select>
                        </div>
                    </div>
                    <div className='inputs'>
                        <input type="text" id="zipCode" name="zipCode" onChange={e => lastName.current = e.target.value}/>
                    </div>
                </div>


                <label className="standard-select" >State</label>
                <div class="input-select" >
                    <div className='inputs'>
                        <div class="select">
                            <select id="standard-select" onChange={e => stateType.current = e.target.value}>
                                <option value="Exact">Exact</option>
                                <option value="Starting" selected>Starting</option>
                                <option value="Any">Any</option>
                            </select>
                        </div>
                    </div>
                    <div className='inputs'>
                        <input type="text" id="zipCode" name="zipCode" onChange={e => state.current = e.target.value}/>
                    </div>
                </div>

                <label className="standard-select">ZipCode</label>
                <div class="input-select" >
                    <div className='inputs'>
                        <div class="select">
                            <select id="standard-select" onChange={e => zipCodeType.current = e.target.value}>
                                <option value="Exact">Exact</option>
                                <option value="Starting" selected>Starting</option>
                                <option value="Any">Any</option>
                            </select>
                        </div>
                    </div>
                    <div className='inputs'>
                        <input type="text" id="zipCode" name="zipCode" onChange={e => zipCode.current = e.target.value}/>
                    </div>
                </div>

                <div class="block">
                    <button id="query-sumbit" class="button success" onClick={uploadInfo}>Submit</button>
                    <label for="checkbox-1" className='more-info'> 
                        <input type="checkbox" id="checkbox-1" name="checkbox-1" onChange={e => option.current = e.target.checked}/>
                        More Info
                    </label>
                </div>

                <div class="block">
              
                    <button id="downloadBtn" className='export-button' onClick={ExportData} value="download">Download</button>
                    
                </div>

            </div>
                {
                    matchesList ? 
                    
                    (
                        matchesList.length == 0 ?
                        <p>No Results Found for {query.current}</p>
                        :
                        <Matches matches_list={matchesList} option={option}></Matches>
                    )
                    :
                    null
                }
            
        </div>
     );
}
 
export default Home;