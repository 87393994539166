import Home from './Home'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        
      <Home></Home>
      
      </header>
    </div>
  );
}

export default App;
