import Match from './Match.js';
import './Matches.css'

const Matches = (props) => {

    const matches = props.matches_list
    const option = props.option

    return (
        <div className="grid-container">
            
            {
                matches && 
                <table className="l2metable">
                    { !option.current ?
                    <>
                    <tr>
                        <th>L2MID</th>
                        <th>HHPERSK</th>
                        <th>FirstName</th>
                        <th>MiddleName</th>
                        <th>LastName</th>
                        <th>Address</th>
                    </tr>
                    <>
                    {
                    matches.map((match, index) => (

                            <tr match-index={index}>
                                <td>{match.L2MID}</td>
                                <td>{match.HHPERSK}</td>
                                <td>{match.first_name}</td>
                                <td>{match.middle_name}</td>
                                <td>{match.last_name}</td>
                                <td>{match.address}</td>
                            </tr>
                        ))
                    }
                    </>
                    </>
                    :

                        <>
                        <tr>
                            <th>Age</th>
                            <th>AgeRange</th>
                            <th>AirConditioning</th>
                            <th>AmexCard</th>
                            <th>AmexPremiumCard</th>
                            <th>AptNumber</th>
                            <th>AptType</th>
                            <th>BirthDate</th>
                            <th>BlockGroup</th>
                            <th>BusOwn</th>
                            <th>CBSACode</th>
                            <th>CBlock</th>
                            <th>CRRT</th>
                            <th>CTract</th>
                            <th>CensusHHIncome</th>
                            <th>CensusHomeValue</th>
                            <th>CensusPctAsian</th>
                            <th>CensusPctBlack</th>
                            <th>CensusPctCaucasian</th>
                            <th>CensusPctHispanic</th>
                            <th>CensusPctIndian</th>
                            <th>CensusPctMulti</th>
                            <th>CensusPctOther</th>
                            <th>CensusPctPacificIsl</th>
                            <th>ComputerOwner</th>
                            <th>CountryOriginCode</th>
                            <th>CountyFIPS</th>
                            <th>CountyName</th>
                            <th>CreditCard</th>
                            <th>CreditCardPremium</th>
                            <th>DMACode</th>
                            <th>DMAName</th>
                            <th>DPBC</th>
                            <th>DPV</th>
                            <th>Deceased</th>
                            <th>DeceasedDate</th>
                            <th>DiscoverCard</th>
                            <th>DiscoverPremiumCard</th>
                            <th>DoNotMail</th>
                            <th>DonorAnimals</th>
                            <th>DonorCharity</th>
                            <th>DonorConservativePolitics</th>
                            <th>DonorEnvironment</th>
                            <th>DonorHealthCharities</th>
                            <th>DonorInternationalAid</th>
                            <th>DonorLiberalPolitics</th>
                            <th>DonorPolitical</th>
                            <th>DonorReligion</th>
                            <th>DonorWildlifeCharities</th>
                            <th>Education</th>
                            <th>EquityLoanAmount</th>
                            <th>EquityLoanDate</th>
                            <th>EquityLoanInterestRate</th>
                            <th>EquityLoanLenderCode</th>
                            <th>EquityLoanRateType</th>
                            <th>EquityLoanType</th>
                            <th>FederalDNC</th>
                            <th>Gender</th>
                            <th>GeoLevel</th>
                            <th>GivenName</th>
                            <th>HHChildrenCount</th>
                            <th>HHFemale0002</th>
                            <th>HHFemale0305</th>
                            <th>HHFemale0610</th>
                            <th>HHFemale1115</th>
                            <th>HHFemale1617</th>
                            <th>HHIncome</th>
                            <th>HHMale0002</th>
                            <th>HHMale0305</th>
                            <th>HHMale0610</th>
                            <th>HHMale1115</th>
                            <th>HHMale1617</th>
                            <th>HHNetWorth</th>
                            <th>HHPERSK</th>
                            <th>HHUnknown0002</th>
                            <th>HHUnknown0305</th>
                            <th>HHUnknown0610</th>
                            <th>HHUnknown1115</th>
                            <th>HHUnknown1617</th>
                            <th>HeadofHousehold</th>
                            <th>HispanicCountryofOrigin</th>
                            <th>HomeMktValue</th>
                            <th>HomePurDate</th>
                            <th>HomePurPrice</th>
                            <th>HouseNumber</th>
                            <th>InterestAntiques</th>
                            <th>InterestArt</th>
                            <th>InterestAviation</th>
                            <th>InterestCats</th>
                            <th>InterestCoins</th>
                            <th>InterestCooking</th>
                            <th>InterestCruise</th>
                            <th>InterestDogs</th>
                            <th>InterestDomesticTravel</th>
                            <th>InterestFoodandWine</th>
                            <th>InterestForeignTravel</th>
                            <th>InterestGardening</th>
                            <th>InterestGourmetCooking</th>
                            <th>InterestHorses</th>
                            <th>InterestHuntingandShooting</th>
                            <th>InterestMusic</th>
                            <th>InterestNASCAR</th>
                            <th>InterestOutdoors</th>
                            <th>InterestPets</th>
                            <th>InterestReading</th>
                            <th>InterestSports</th>
                            <th>InterestStamps</th>
                            <th>InterestTennis</th>
                            <th>InterestTheater</th>
                            <th>InterestTravel</th>
                            <th>InterestWeightLoss</th>
                            <th>Job</th>
                            <th>JobCode</th>
                            <th>L2MID</th>
                            <th>LACS</th>
                            <th>LanguageCode</th>
                            <th>LastActivityDate</th>
                            <th>Latitude</th>
                            <th>LengthRes</th>
                            <th>Locality</th>
                            <th>Longitude</th>
                            <th>MailResp</th>
                            <th>MaritalStat</th>
                            <th>MiddleName</th>
                            <th>MobilePhone</th>
                            <th>MortgageAmount</th>
                            <th>MortgageDate</th>
                            <th>MortgageInterestRate</th>
                            <th>MortgageLenderCode</th>
                            <th>MortgageLoanType</th>
                            <th>MortgageRateType</th>
                            <th>NamePrefix</th>
                            <th>NameSuffix</th>
                            <th>OwnerProb</th>
                            <th>OwnerRent</th>
                            <th>PostDir</th>
                            <th>PreDir</th>
                            <th>PresenceChildren</th>
                            <th>PropertyType</th>
                            <th>PurchaseAutoParts</th>
                            <th>PurchaseBooks</th>
                            <th>PurchaseChildrenApparel</th>
                            <th>PurchaseCrafts</th>
                            <th>PurchaseElectronics</th>
                            <th>PurchaseFemaleProd</th>
                            <th>PurchaseGenMerch</th>
                            <th>PurchaseGifts</th>
                            <th>PurchaseHomeandGarden</th>
                            <th>PurchaseInfantApparel</th>
                            <th>PurchaseMailOrder</th>
                            <th>PurchaseMenApparel</th>
                            <th>PurchaseOnlineIndicator</th>
                            <th>PurchaseSportsandLeisure</th>
                            <th>PurchaseUpscaleMerch</th>
                            <th>RegionOriginCode</th>
                            <th>ReligionCode</th>
                            <th>RetailGasCard</th>
                            <th>StType</th>
                            <th>StateCode</th>
                            <th>StateFIPS</th>
                            <th>Street</th>
                            <th>SubscribeCulinaryMag</th>
                            <th>SubscribeDoItYourselfMag</th>
                            <th>SubscribeFamilyMag</th>
                            <th>SubscribeFemaleMag</th>
                            <th>SubscribeFinancialMag</th>
                            <th>SubscribeGardenMag</th>
                            <th>SubscribeHealthMag</th>
                            <th>SubscribeMaleMag</th>
                            <th>SubscribeOpportunitySeekerMag</th>
                            <th>SubscribePhotographyMag</th>
                            <th>Surname</th>
                            <th>SwimmingPool</th>
                            <th>TelephoneNumber</th>
                            <th>Timezone</th>
                            <th>UnitType</th>
                            <th>Voter2ndAmendment</th>
                            <th>VoterActiveMilitary</th>
                            <th>VoterEducation</th>
                            <th>VoterFECDonor</th>
                            <th>VoterGunControl</th>
                            <th>VoterHealthcare</th>
                            <th>VoterIdeologyProfile</th>
                            <th>VoterMilitarySupporters</th>
                            <th>VoterOrgLabor</th>
                            <th>VoterParty</th>
                            <th>VoterStatus</th>
                            <th>VoterSwing</th>
                            <th>WaterSource</th>
                            <th>YearBuilt</th>
                            <th>ZipCode</th>
                            <th>ZipPlus4</th>
                        </tr>
                        <>
                        {
                        matches.map((match, index) => (
                        <tr match-index={index}>
                            <td>{match.Age}</td>
                            <td>{match.AgeRange}</td>
                            <td>{match.AirConditioning}</td>
                            <td>{match.AmexCard}</td>
                            <td>{match.AmexPremiumCard}</td>
                            <td>{match.AptNumber}</td>
                            <td>{match.AptType}</td>
                            <td>{match.BirthDate}</td>
                            <td>{match.BlockGroup}</td>
                            <td>{match.BusOwn}</td>
                            <td>{match.CBSACode}</td>
                            <td>{match.CBlock}</td>
                            <td>{match.CRRT}</td>
                            <td>{match.CTract}</td>
                            <td>{match.CensusHHIncome}</td>
                            <td>{match.CensusHomeValue}</td>
                            <td>{match.CensusPctAsian}</td>
                            <td>{match.CensusPctBlack}</td>
                            <td>{match.CensusPctCaucasian}</td>
                            <td>{match.CensusPctHispanic}</td>
                            <td>{match.CensusPctIndian}</td>
                            <td>{match.CensusPctMulti}</td>
                            <td>{match.CensusPctOther}</td>
                            <td>{match.CensusPctPacificIsl}</td>
                            <td>{match.ComputerOwner}</td>
                            <td>{match.CountryOriginCode}</td>
                            <td>{match.CountyFIPS}</td>
                            <td>{match.CountyName}</td>
                            <td>{match.CreditCard}</td>
                            <td>{match.CreditCardPremium}</td>
                            <td>{match.DMACode}</td>
                            <td>{match.DMAName}</td>
                            <td>{match.DPBC}</td>
                            <td>{match.DPV}</td>
                            <td>{match.Deceased}</td>
                            <td>{match.DeceasedDate}</td>
                            <td>{match.DiscoverCard}</td>
                            <td>{match.DiscoverPremiumCard}</td>
                            <td>{match.DoNotMail}</td>
                            <td>{match.DonorAnimals}</td>
                            <td>{match.DonorCharity}</td>
                            <td>{match.DonorConservativePolitics}</td>
                            <td>{match.DonorEnvironment}</td>
                            <td>{match.DonorHealthCharities}</td>
                            <td>{match.DonorInternationalAid}</td>
                            <td>{match.DonorLiberalPolitics}</td>
                            <td>{match.DonorPolitical}</td>
                            <td>{match.DonorReligion}</td>
                            <td>{match.DonorWildlifeCharities}</td>
                            <td>{match.Education}</td>
                            <td>{match.EquityLoanAmount}</td>
                            <td>{match.EquityLoanDate}</td>
                            <td>{match.EquityLoanInterestRate}</td>
                            <td>{match.EquityLoanLenderCode}</td>
                            <td>{match.EquityLoanRateType}</td>
                            <td>{match.EquityLoanType}</td>
                            <td>{match.FederalDNC}</td>
                            <td>{match.Gender}</td>
                            <td>{match.GeoLevel}</td>
                            <td>{match.GivenName}</td>
                            <td>{match.HHChildrenCount}</td>
                            <td>{match.HHFemale0002}</td>
                            <td>{match.HHFemale0305}</td>
                            <td>{match.HHFemale0610}</td>
                            <td>{match.HHFemale1115}</td>
                            <td>{match.HHFemale1617}</td>
                            <td>{match.HHIncome}</td>
                            <td>{match.HHMale0002}</td>
                            <td>{match.HHMale0305}</td>
                            <td>{match.HHMale0610}</td>
                            <td>{match.HHMale1115}</td>
                            <td>{match.HHMale1617}</td>
                            <td>{match.HHNetWorth}</td>
                            <td>{match.HHPERSK}</td>
                            <td>{match.HHUnknown0002}</td>
                            <td>{match.HHUnknown0305}</td>
                            <td>{match.HHUnknown0610}</td>
                            <td>{match.HHUnknown1115}</td>
                            <td>{match.HHUnknown1617}</td>
                            <td>{match.HeadofHousehold}</td>
                            <td>{match.HispanicCountryofOrigin}</td>
                            <td>{match.HomeMktValue}</td>
                            <td>{match.HomePurDate}</td>
                            <td>{match.HomePurPrice}</td>
                            <td>{match.HouseNumber}</td>
                            <td>{match.InterestAntiques}</td>
                            <td>{match.InterestArt}</td>
                            <td>{match.InterestAviation}</td>
                            <td>{match.InterestCats}</td>
                            <td>{match.InterestCoins}</td>
                            <td>{match.InterestCooking}</td>
                            <td>{match.InterestCruise}</td>
                            <td>{match.InterestDogs}</td>
                            <td>{match.InterestDomesticTravel}</td>
                            <td>{match.InterestFoodandWine}</td>
                            <td>{match.InterestForeignTravel}</td>
                            <td>{match.InterestGardening}</td>
                            <td>{match.InterestGourmetCooking}</td>
                            <td>{match.InterestHorses}</td>
                            <td>{match.InterestHuntingandShooting}</td>
                            <td>{match.InterestMusic}</td>
                            <td>{match.InterestNASCAR}</td>
                            <td>{match.InterestOutdoors}</td>
                            <td>{match.InterestPets}</td>
                            <td>{match.InterestReading}</td>
                            <td>{match.InterestSports}</td>
                            <td>{match.InterestStamps}</td>
                            <td>{match.InterestTennis}</td>
                            <td>{match.InterestTheater}</td>
                            <td>{match.InterestTravel}</td>
                            <td>{match.InterestWeightLoss}</td>
                            <td>{match.Job}</td>
                            <td>{match.JobCode}</td>
                            <td>{match.L2MID}</td>
                            <td>{match.LACS}</td>
                            <td>{match.LanguageCode}</td>
                            <td>{match.LastActivityDate}</td>
                            <td>{match.Latitude}</td>
                            <td>{match.LengthRes}</td>
                            <td>{match.Locality}</td>
                            <td>{match.Longitude}</td>
                            <td>{match.MailResp}</td>
                            <td>{match.MaritalStat}</td>
                            <td>{match.MiddleName}</td>
                            <td>{match.MobilePhone}</td>
                            <td>{match.MortgageAmount}</td>
                            <td>{match.MortgageDate}</td>
                            <td>{match.MortgageInterestRate}</td>
                            <td>{match.MortgageLenderCode}</td>
                            <td>{match.MortgageLoanType}</td>
                            <td>{match.MortgageRateType}</td>
                            <td>{match.NamePrefix}</td>
                            <td>{match.NameSuffix}</td>
                            <td>{match.OwnerProb}</td>
                            <td>{match.OwnerRent}</td>
                            <td>{match.PostDir}</td>
                            <td>{match.PreDir}</td>
                            <td>{match.PresenceChildren}</td>
                            <td>{match.PropertyType}</td>
                            <td>{match.PurchaseAutoParts}</td>
                            <td>{match.PurchaseBooks}</td>
                            <td>{match.PurchaseChildrenApparel}</td>
                            <td>{match.PurchaseCrafts}</td>
                            <td>{match.PurchaseElectronics}</td>
                            <td>{match.PurchaseFemaleProd}</td>
                            <td>{match.PurchaseGenMerch}</td>
                            <td>{match.PurchaseGifts}</td>
                            <td>{match.PurchaseHomeandGarden}</td>
                            <td>{match.PurchaseInfantApparel}</td>
                            <td>{match.PurchaseMailOrder}</td>
                            <td>{match.PurchaseMenApparel}</td>
                            <td>{match.PurchaseOnlineIndicator}</td>
                            <td>{match.PurchaseSportsandLeisure}</td>
                            <td>{match.PurchaseUpscaleMerch}</td>
                            <td>{match.RegionOriginCode}</td>
                            <td>{match.ReligionCode}</td>
                            <td>{match.RetailGasCard}</td>
                            <td>{match.StType}</td>
                            <td>{match.StateCode}</td>
                            <td>{match.StateFIPS}</td>
                            <td>{match.Street}</td>
                            <td>{match.SubscribeCulinaryMag}</td>
                            <td>{match.SubscribeDoItYourselfMag}</td>
                            <td>{match.SubscribeFamilyMag}</td>
                            <td>{match.SubscribeFemaleMag}</td>
                            <td>{match.SubscribeFinancialMag}</td>
                            <td>{match.SubscribeGardenMag}</td>
                            <td>{match.SubscribeHealthMag}</td>
                            <td>{match.SubscribeMaleMag}</td>
                            <td>{match.SubscribeOpportunitySeekerMag}</td>
                            <td>{match.SubscribePhotographyMag}</td>
                            <td>{match.Surname}</td>
                            <td>{match.SwimmingPool}</td>
                            <td>{match.TelephoneNumber}</td>
                            <td>{match.Timezone}</td>
                            <td>{match.UnitType}</td>
                            <td>{match.Voter2ndAmendment}</td>
                            <td>{match.VoterActiveMilitary}</td>
                            <td>{match.VoterEducation}</td>
                            <td>{match.VoterFECDonor}</td>
                            <td>{match.VoterGunControl}</td>
                            <td>{match.VoterHealthcare}</td>
                            <td>{match.VoterIdeologyProfile}</td>
                            <td>{match.VoterMilitarySupporters}</td>
                            <td>{match.VoterOrgLabor}</td>
                            <td>{match.VoterParty}</td>
                            <td>{match.VoterStatus}</td>
                            <td>{match.VoterSwing}</td>
                            <td>{match.WaterSource}</td>
                            <td>{match.YearBuilt}</td>
                            <td>{match.ZipCode}</td>
                            <td>{match.ZipPlus4}</td>
                        </tr>
                        
                    ))
                    }
                    </>
                    </>
                    }
  
                </table>
            }
        </div>
      );
};
  
export default Matches;